footer {
  display: flex;
  margin: 20px;
  flex-direction: column;
  border-top: 1px dotted #ccc;
  padding-top: 20px;
}

@media screen and (min-width: 768px) {
  footer {
    align-items: center;
    width: 90%;
    margin: 20px 0;
  }
}

footer ul {
  margin: 0;
  padding: 0;
  display: inline-flex;
  color: #333;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  footer ul {
    flex-direction: row;
  }
}

footer li {
  list-style: none;
  margin-right: 20px;
}

footer li span {
  margin-left: 4px;
}

footer li:last-child {
  margin-right: 0;
}

.copyright {
  color: #333;
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .copyright {
    margin-top: 0;
  }
}
