.title-container {
  padding: 20px;
  background-image: url("../img/background-beautiful-blur-sky-627823.jpg");
  display: none;
}

@media screen and (min-width: 768px) {
  .title-container {
    display: flex;
    justify-content: space-between;
  }
}

.title {
  margin: 0;
}

.slogan {
  margin: 0;
  text-align: left;
}

.topnav {
  overflow: hidden;
  background-color: #333;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topnav ul {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .topnav ul {
    flex-direction: row;
  }
}

.topnav ul li {
  list-style: none;
  display: none;
  align-items: center;
  height: 50px;
  flex: 1;
}

@media screen and (min-width: 768px) {
  .topnav ul li {
    display: flex;
  }
}

.topnav.open ul li {
  display: flex;
}

.topnav ul li:first-child {
  display: flex;
  margin-left: 0;
}

/* Style navigation menu links */
.topnav ul a,
.topnav ul button {
  color: white;
  text-decoration: none;
  font-size: 17px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 6px;
}

.topnav .icon {
  color: white;
  background: #333;
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .topnav .icon {
    display: none;
  }
}

/* Add a grey background color on mouse-over */
.topnav a:hover,
.topnav button:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active link (or home/logo) */
.topnav .active {
  background-color: #cad0da;
  color: #333;
}

.language-selector {
  margin: 0;
  list-style: none;
  display: flex;
  padding: 0;
  align-items: flex-end;
}

.language-selector-item {
  margin-left: 6px;
}

.language-selector-button {
  color: #333;
  text-decoration: none;
  align-items: normal;
  cursor: pointer;
  background: none;
  padding: 0;
}

.language-selector-button--selected {
  font-weight: bold;
}

.topnav ul li.language-button-in-menu button {
  text-decoration: none;
  align-items: normal;
  cursor: pointer;
  background: #333;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .topnav ul li.language-button-in-menu {
    display: none;
  }
}
