html {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  background-color: #cfcfcf;
}

body {
  display: flex;
  flex-direction: column;
  color: #111;
  background-color: #ffffff;
}

@media screen and (min-width: 768px) {
  body {
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  body {
    margin: auto;
    width: 80%;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

main {
  display: flex;
  margin: 10px 10px 0 10px;
  padding: 0 10px 0 10px;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  main {
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  main {
    margin: 20px 20px 0 20px;
    padding: 0;
  }
}

h1,
h2,
h3 {
  color: #333;
  margin-top: 0;
  text-align: left;
}

img {
  width: 100%;
}

a {
  color: #111;
  font-weight: bold;
  text-decoration: none;
}

button {
  border: none;
}

article {
  flex: 8;
}

aside {
  flex: 4;
}

@media screen and (min-width: 768px) {
  aside {
    padding-left: 20px;
  }
}

header {
  width: 100%;
}

p {
  text-align: justify;
}

@media screen and (min-width: 768px) {
  .columns-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .columns-2 > section,
  .columns-2 > div {
    width: 50%;
    flex: 1 0 48%;
  }

  .columns-2 > section:nth-child(odd),
  .columns-2 > div:nth-child(odd) {
    padding-right: 10px;
  }

  .columns-2 > section:nth-child(even),
  .columns-2 > div:nth-child(even) {
    padding-left: 10px;
  }
}

.prop-list {
  list-style: none;
  padding-left: 0;
}

.prop-list li {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .prop-list li {
    display: flex;
  }
}

.prop-list span {
  display: flex;
}
.prop-list span:first-child {
  font-weight: bold;
  min-width: 120px;
}

.prop-list .prop-list-extra-space {
  margin-top: 40px;
}

.swish-icon {
  width: 70px;
}

.payment-icon {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #7b8650;
}

.payment-icon-list {
  display: flex;
  justify-content: space-between;
  max-width: 160px;
}
